@import "../../../baseTheme/settings";

.featured-post {
    picture {
        border-radius: 12px;
        overflow: hidden;
        display: block;

        img {
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
        }
    }

    .featured-content-area {
        .tags {
            font-size: 12px;
            letter-spacing: -2%;
            margin-bottom: 20px;
            display: block;
        }

        h2,
        h1, h3, h4, h5, h6, p {
            margin-bottom: 20px;
        }

        h2 {
            @include media-breakpoint-down(md) {
                font-size: 30px;
                line-height: 32px;
            }
        }

        p {
            @include media-breakpoint-down(md) {
                font-size: 14px;
                line-height: 18px;
            }
        }
        .btn {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}